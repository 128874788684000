import React, { useState } from "react"
import styled from "styled-components"

const InputComponent = props => {
  return (
    <InputWrapper
      width={props.width}
      type={props.type}
      placeholder={props.placeholder}
      value={props.value}
      onChange={e => props.handleOnChange(e.target.value)}
    />
  )
}

const InputWrapper = styled.input`
  width: ${props => props.width};
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  border-radius: 2rem;
  outline: none;
  border: 1px solid #454545;
  /*  padding-top: 0.3rem;
  padding-left: 0.8rem; */
  background-color: #010101;
  color: #cccccc;
  /*   padding-top: 1.1em;
  padding-left: 1.8rem;
  padding-right: 1.8rem; */
  padding: 1rem 1.8rem;
  padding-top: 1.2rem;
`

export default InputComponent
