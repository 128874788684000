import React, { useEffect } from "react"
import { Router } from "@reach/router"
import { navigate } from "gatsby"

import Profile from "../components/profile"
import PrivateRoute from "../components/private-route"
import Login from "../components/dashboard-login"
import LGS from "../components/dashboard-lgs"
import MyDecks from "../components/dashboard-myDecks"
import FavoriteDecks from "../components/dashboard-FavoriteDecks"
import Friends from "../components/dashboard-Friends"
import Subscribe from "../components/dashboard-subscribe"
import MyProfile from "../components/dashboard-MyProfile"
import SEO from "./../components/seo"

const Dashboard = ({ location }) => {
  useEffect(() => {
    if (location.pathname.match(/^\/dashboard\/?$/)) {
      navigate("/dashboard/login/", { replace: true })
    }
  }, [location.pathname])

  return (
    <div>
      <SEO
        blockIndexing={true}
        slug="/mtg-deck-builder/"
        title="Player Dashboard - MTG Builders"
        description="Personalize your Feed by following players of your choice. Favorite decks and export them to MTG Arena or MTGO."
      />
      <Profile />
      <Router>
        <Login path="/dashboard/login/" />
        <PrivateRoute path="/dashboard/subscribe/" component={Subscribe} />
        <PrivateRoute path="/dashboard/lgs/" component={LGS} />
        <PrivateRoute path="/dashboard/my-decks/" component={MyDecks} />
        <PrivateRoute
          path="/dashboard/favorite-decks/"
          component={FavoriteDecks}
        />
        <PrivateRoute path="/dashboard/following/" component={Friends} />
        <PrivateRoute path="/dashboard/profile/" component={MyProfile} />
      </Router>
    </div>
  )
}

export default Dashboard
