import React, { useState, useEffect, useContext, useMemo } from "react"
import { navigate, useStaticQuery, Link, graphql } from "gatsby"
import { useQueryCache, useQuery } from "react-query"
import { useToasts } from "react-toast-notifications"
import styled from "styled-components"
import Img from "gatsby-image"

import SessionContext from "./../context/session"
import { faunaQueries } from "../fauna/query-manager"
import { safeVerifyError } from "../fauna/helpers/errors"

import DecksBar from "../components/decksBar"

import { logoutBecauseUnauthorized } from "../fauna/helpers/util"

import svgpatreon from "./../assets/svgpatreon"
import svgtwitch from "./../assets/svgtwitch"
import svgfacebook from "./../assets/svgfacebook"
import svgtwitter from "./../assets/svgtwitter"
import svgyoutube from "./../assets/svgyoutube"
import svgheart from "../assets/svgheart"
import Spinner from "./Spinner"

function calculateCosmeticsPool(...args) {
  return args.flat()
}

const Friends = props => {
  // eq: options.name (gatsby-config)
  const data = useStaticQuery(graphql`
    query {
      m15tokensQ: allFile(
        filter: { sourceInstanceName: { eq: "m15tokensFonte" } }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      m15tokensnyxQ: allFile(
        filter: { sourceInstanceName: { eq: "m15tokensnyxFonte" } }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      moldtokensQ: allFile(
        filter: { sourceInstanceName: { eq: "moldtokensFonte" } }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      artsCutQ: allFile(
        filter: { sourceInstanceName: { eq: "artsCutFonte" } }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const allCosmeticsFromGatsby = useMemo(
    () =>
      calculateCosmeticsPool(
        data.moldtokensQ.edges,
        data.m15tokensnyxQ.edges,
        data.m15tokensQ.edges,
        data.artsCutQ.edges
      ),
    [data]
  )

  const sessionContext = useContext(SessionContext)
  const { user } = sessionContext.state
  const { addToast } = useToasts()

  const queryCache = useQueryCache()

  const queryInfo = use_get_friends(
    queryCache,
    addToast,
    logoutBecauseUnauthorized,
    sessionContext
  )
  /*   console.log("Friends/queryInfo: ", queryInfo) */

  useEffect(() => {
    if (!user && faunaQueries.getUser()) {
      sessionContext.dispatch({ type: "login", data: faunaQueries.getUser() })
    }
    return function cleanup() {
      /*       console.log("Friends.js/unmounting") */
    }
  }, [user, sessionContext])

  return (
    <PageLayout>
      {queryInfo.isLoading ? (
        <Spinner />
      ) : queryInfo.isSuccess && queryInfo.data?.myError ? (
        <h1>ERROR FR001</h1>
      ) : queryInfo.isSuccess && !queryInfo.data ? (
        <h1>ERROR FR002</h1>
      ) : queryInfo.isSuccess && queryInfo.data ? (
        <Flex_Profiles>
          {queryInfo.data.data.map(({ data: sFriend }) => (
            <Link to={`/user/${sFriend.alias}`} key={sFriend.alias}>
              <OuterProfile>
                <Box
                  myProfileCardShape={sFriend.shape}
                  userAlias={sFriend.alias}
                >
                  <GatsbyImgProfile
                    fluid={
                      allCosmeticsFromGatsby.find(
                        edge => edge.node.name === sFriend.back
                      )?.node?.childImageSharp?.fluid
                    }
                  />
                  <WrapGatsbyImgProfileOverlay>
                    <GatsbyImgProfile
                      fluid={
                        allCosmeticsFromGatsby.find(
                          edge =>
                            edge.node.name === sFriend.shape + sFriend.sfondo
                        )?.node?.childImageSharp?.fluid
                      }
                    />
                  </WrapGatsbyImgProfileOverlay>
                </Box>
                <WrapperLogos>
                  <ExtLink
                    style={{
                      "--display": sFriend.patreon ? "inline-block" : "none",
                    }}
                    as="a"
                    href={sFriend.patreon}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {svgpatreon}
                  </ExtLink>

                  <ExtLink
                    style={{
                      "--display": sFriend.twitch ? "inline-block" : "none",
                    }}
                    as="a"
                    href={sFriend.twitch}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {svgtwitch}
                  </ExtLink>
                  <ExtLink
                    style={{
                      "--display": sFriend.youtube ? "inline-block" : "none",
                    }}
                    as="a"
                    href={sFriend.youtube}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {svgyoutube}
                  </ExtLink>
                  <ExtLink
                    style={{
                      "--display": sFriend.twitter ? "inline-block" : "none",
                    }}
                    as="a"
                    href={"twittoqui"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {svgtwitter}
                  </ExtLink>
                  <ExtLink
                    style={{
                      "--display": sFriend.facebook ? "inline-block" : "none",
                    }}
                    as="a"
                    href={
                      "https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/title"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {svgfacebook}
                  </ExtLink>
                </WrapperLogos>
              </OuterProfile>
            </Link>
          ))}
        </Flex_Profiles>
      ) : queryInfo.isIdle ? (
        <h1>ERROR FR003</h1>
      ) : queryInfo.isError ? (
        <h1>ERROR FR004</h1>
      ) : (
        <h1>ERROR FR005</h1>
      )}
    </PageLayout>
  )
}

export default Friends

function use_get_friends(
  queryCache,
  addToast,
  logoutBecauseUnauthorized,
  sessionContext
) {
  return useQuery(
    [`QM_get_friends`],
    (prefix, key) =>
      fetch_get_friends(
        queryCache,
        addToast,
        logoutBecauseUnauthorized,
        sessionContext
      ),
    {
      staleTime: 0,
      /* keepPreviousData: true, */

      onError: (error, values, rollback) => {
        /*         console.log("use_get_friends/onError: ", error) */
        if (
          error.error ||
          error.description === "Unauthorized" ||
          error.message === "permission denied" ||
          error.message === "Failed to fetch" // DAFARE Aggiunto dopo. Prima di implementare Fauna.
        ) {
          /*           console.log("uno") */
          logoutBecauseUnauthorized(
            sessionContext,
            addToast,
            error.error || error.message
          )
        } else {
          /*           console.log("due") */
          const errorDescription = safeVerifyError(error, [
            "requestResult",
            "responseContent",
            "errors",
            0,
            "cause",
            0,
            "description",
          ])
          addToast(errorDescription, { appearance: "error" })
        }
      },
      onSuccess: (data, values) => {
        // console.log("use_get_friends/onSuccess: ", data)
        // volendo, potrei creare una entry [QM_get_user_info, alias] per ogni obj in questa array.
        // ricorda pero' che UDF_get_user_info aggiunge field come isFollowedByCurUser,
        // mentre UDF_get_friends fa semplicemente un Get su un userRef
        /*         data.data.forEach(singleDeck => {
          let singleDeckMod = {}
          if (typeof singleDeck["first"] === "number") {
            singleDeckMod = {
              ...singleDeck,
              likes: singleDeck["first"],
              created: singleDeck["second"],
            }
          } else {
            singleDeckMod = {
              ...singleDeck,
              likes: singleDeck["second"],
              created: singleDeck["first"],
            }
          }
          queryCache.setQueryData(
            ["initial_deck", singleDeck.deckRef.value.id],
            singleDeckMod
          )
        }) */
      },
    }
  )
}

async function fetch_get_friends(
  queryCache,
  addToast,
  logoutBecauseUnauthorized,
  sessionContext
) {
  try {
    const response = await faunaQueries.QM_get_friends() // q.Map(Var("page"), Lambda("userRef", Get(Var("userRef"))))
    /*     console.log("fetch_get_friends -> response", response) */
    return response
  } catch (err) {
    /*     console.log("fetch_get_friends -> err", err) */
    /* logoutBecauseUnauthorized(sessionContext, addToast, err) */
    throw err
  }
}

const PageLayout = styled.div`
  background: radial-gradient(#555, #111);
  padding: 3rem;
  min-height: 100vh;
`

const Flex_Profiles = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`
const OuterProfile = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin-right: 3.5rem;
  margin-bottom: 3rem;
`

const Box = styled.div`
  /* cursor: pointer; */
  width: 27rem;
  height: 37.5rem;
  perspective: 600px;
  transition: transform 0.25s ease-out;
  position: relative;
  margin-bottom: 3rem;

  filter: drop-shadow(-14px -6px 10px black);

  &:before {
    // pseudoelement non fa confondere Card3D
    content: "${props => props.userAlias}";
    text-align: center;
    position: absolute;
    top: ${props =>
      props.myProfileCardShape === "moldtk" ? "76.8%" : "82.5%"};
    z-index: 1;
    width: 100%;
  }
`

const WrapGatsbyImgProfileOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -100;
`
const GatsbyImgProfile = styled(Img)`
  width: 27rem;
  height: 37.5rem;
  transition: transform 0.25s ease-out;
  border-radius: 1.5rem;
`

const WrapperLogos = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`

const ExtLink = styled.a`
  /*   display: ${props => (props.href ? "inline-block" : "none")}; */
  display: var(--display);
  margin: 5px;

  &:hover {
    svg {
      filter: drop-shadow(0px 4px 6px #ff974c);
      opacity: 1;
    }
  }

  svg {
    filter: drop-shadow(0px 4px 6px black);
    opacity: 0.5;
  }
`
