import React, { useState, useEffect, useContext, useRef } from "react"
import { navigate, Link, useStaticQuery, graphql } from "gatsby"
import { useQueryCache, useQuery } from "react-query"
import { useToasts } from "react-toast-notifications"
import styled from "styled-components"
import { motion } from "framer-motion"
import Img from "gatsby-image"

import SessionContext from "./../context/session"
import { faunaQueries } from "../fauna/query-manager"
import { safeVerifyError } from "../fauna/helpers/errors"
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js"
import { createSubscription, onApprove, mapTiers } from "../fauna/helpers/util"
import svgarnold from "../assets/svgarnold"

const defOpt = {
  "client-id": `AS5uEfxpolCw2e9586N5_5L1DY_txF8wk3W79GU7fD1M6bvqsWw70-CqpYpM1YIqxwx6gNFDMBONFUgs`,
  components: "buttons",
  vault: true,
  currency: "USD",
  intent: "subscription",
}

function Card3D(ev, imgElRef) {
  let mouseX = ev.nativeEvent.offsetX
  let mouseY = ev.nativeEvent.offsetY

  let rotateY = map(mouseX, 0, 300, -25, 25) // 180
  let rotateX = map(mouseY, 0, 418, 25, -25) // 250
  let brightness = map(mouseY, 0, 418, 1.15, 0.85) // 250

  imgElRef.current.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`
  imgElRef.current.style.filter = `brightness(${brightness})`
}

function map(val, minA, maxA, minB, maxB) {
  return minB + ((val - minA) * (maxB - minB)) / (maxA - minA)
}

const Subscribe = () => {
  // eq: options.name (gatsby-config)
  const data = useStaticQuery(graphql`
    query {
      subcards: allFile(
        filter: { sourceInstanceName: { eq: "subcardsFonte" } }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const sessionContext = useContext(SessionContext)
  const { user } = sessionContext.state
  const { addToast } = useToasts()

  const [{ isPendingPayPal }] = usePayPalScriptReducer()
  const [payPalError, setPayPalError] = useState(null)
  /* console.log("payPalError: ", payPalError) */

  const subRareMref = useRef(null)
  const subRareYref = useRef(null)
  const subUncommonYref = useRef(null)
  const subUncommonMref = useRef(null)

  useEffect(() => {
    if (!user && faunaQueries.getUser()) {
      sessionContext.dispatch({ type: "login", data: faunaQueries.getUser() })
    }
    return function cleanup() {
      /*       console.log("Friends.js/unmounting") */
    }
  }, [user, sessionContext])

  return (
    <PageLayout>
      <ST_svg
        style={{
          "--stroke": "none",
          "--width": "72px",
          "--height": "72px",
          "--fill": "#ccc",
        }}
      >
        {svgarnold}
      </ST_svg>
      {user?.tier === "silver" || user?.tier === "rare" ? (
        <Flex_Col>
          <MyText
            as={"h2"}
            style={{
              "--font-size": "3rem",
              "--margin-bottom": "3rem",
              "--text-shadow": "none",
              "--text-shadow-hover": "none",
            }}
          >
            Personal Discord code: {user?.discordCode["@ref"].id}
          </MyText>
          <MyText
            as={"h2"}
            style={{
              "--font-size": "3rem",
              "--margin-bottom": "3rem",
              "--text-shadow": "none",
              "--text-shadow-hover": "none",
            }}
          >
            Discord link: https://discord.gg/Cdf5uPqwQY
          </MyText>
          <MyText
            as={"h2"}
            style={{
              "--font-size": "2.5rem",
              "--margin-bottom": "0.3rem",
              "--text-shadow": "none",
              "--text-shadow-hover": "none",
            }}
          >
            To gain access to the private channels, send me a message on discord
            to the Admin with your personal Discord Code.
          </MyText>
        </Flex_Col>
      ) : payPalError ? (
        <h1>
          There was a problem with PayPal. Try to log-out and log-in. Read the{" "}
          <Link to="/faq/">FAQ</Link> page.
        </h1>
      ) : (
        <Flex_Row>
          <SubBox>
            <WrapperGatsbyImg
              onMouseMove={ev => {
                Card3D(ev, subUncommonMref)
              }}
              onMouseLeave={ev => {
                subUncommonMref.current.style.transform =
                  "rotateX(0deg) rotateY(0deg) scale(1)"
                subUncommonMref.current.style.filter = "brightness(1)"
              }}
              ref={subUncommonMref}
            >
              <GatsbyImgProfile
                fluid={
                  data.subcards.edges.find(
                    edge => edge.node.name === "subUncommonM"
                  ).node.childImageSharp.fluid
                }
              />
            </WrapperGatsbyImg>
            <MyText
              as={"h2"}
              style={{
                "--font-size": "3rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Uncommon ~ Monthly
            </MyText>
            <MyText
              as={"h2"}
              style={{
                "--font-size": "2.5rem",
                "--margin-bottom": "3.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              $4.99/month
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Unlock social features
            </MyText>
            {/*             <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Unlock all cards in Binder
            </MyText> */}
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Discord access
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Create up to 20 decks
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "3.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Export decks
            </MyText>
            <PayPalButtons
              style={{ layout: "vertical" }}
              createSubscription={(data, actions) =>
                createSubscription(data, actions, mapTiers["silver"])
              }
              onApprove={(data, actions) =>
                onApprove(data, actions, addToast, sessionContext)
              }
              onError={err => {
                setPayPalError(err)
              }}
              /* {...defOpt} */
            />
          </SubBox>
          <SubBox>
            <WrapperGatsbyImg
              onMouseMove={ev => {
                Card3D(ev, subUncommonYref)
              }}
              onMouseLeave={ev => {
                subUncommonYref.current.style.transform =
                  "rotateX(0deg) rotateY(0deg) scale(1)"
                subUncommonYref.current.style.filter = "brightness(1)"
              }}
              ref={subUncommonYref}
            >
              <GatsbyImgProfile
                fluid={
                  data.subcards.edges.find(
                    edge => edge.node.name === "subUncommonY"
                  ).node.childImageSharp.fluid
                }
              />
            </WrapperGatsbyImg>
            <MyText
              as={"h2"}
              style={{
                "--font-size": "3rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Uncommon ~ Annual
            </MyText>
            <MyText
              as={"h2"}
              style={{
                "--font-size": "2.5rem",
                "--margin-bottom": "3.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              $19.99/year
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Unlock social features
            </MyText>
            {/*             <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Unlock all cards in Binder
            </MyText> */}
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Discord access
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Create up to 20 decks
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Export decks
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "3.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              +100 Coins
            </MyText>
            <PayPalButtons
              style={{ layout: "vertical" }}
              createSubscription={(data, actions) =>
                createSubscription(data, actions, mapTiers["silverY"])
              }
              onApprove={(data, actions) =>
                onApprove(data, actions, addToast, sessionContext)
              }
              onError={err => {
                setPayPalError(err)
              }}
              /* {...defOpt} */
            />
          </SubBox>
          <SubBox>
            <WrapperGatsbyImg
              onMouseMove={ev => {
                Card3D(ev, subRareMref)
              }}
              onMouseLeave={ev => {
                subRareMref.current.style.transform =
                  "rotateX(0deg) rotateY(0deg) scale(1)"
                subRareMref.current.style.filter = "brightness(1)"
              }}
              ref={subRareMref}
            >
              <GatsbyImgProfile
                fluid={
                  data.subcards.edges.find(
                    edge => edge.node.name === "subRareM"
                  ).node.childImageSharp.fluid
                }
              />
            </WrapperGatsbyImg>
            <MyText
              as={"h2"}
              style={{
                "--font-size": "3rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Rare ~ Monthly
            </MyText>
            <MyText
              as={"h2"}
              style={{
                "--font-size": "2.5rem",
                "--margin-bottom": "3.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              $9.99/month
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Unlock social features
            </MyText>
            {/*             <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Unlock all cards in Binder
            </MyText> */}
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Discord access
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Create up to 60 decks
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Export decks
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "3.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Create private decks
            </MyText>
            <PayPalButtons
              style={{ layout: "vertical" }}
              createSubscription={(data, actions) =>
                createSubscription(data, actions, mapTiers["rare"])
              }
              onApprove={(data, actions) =>
                onApprove(data, actions, addToast, sessionContext)
              }
              onError={err => {
                setPayPalError(err)
              }}
              /* {...defOpt} */
            />
          </SubBox>
          <SubBox>
            <WrapperGatsbyImg
              onMouseMove={ev => {
                Card3D(ev, subRareYref)
              }}
              onMouseLeave={ev => {
                subRareYref.current.style.transform =
                  "rotateX(0deg) rotateY(0deg) scale(1)"
                subRareYref.current.style.filter = "brightness(1)"
              }}
              ref={subRareYref}
            >
              <GatsbyImgProfile
                fluid={
                  data.subcards.edges.find(
                    edge => edge.node.name === "subRareY"
                  ).node.childImageSharp.fluid
                }
              />
            </WrapperGatsbyImg>
            <MyText
              as={"h2"}
              style={{
                "--font-size": "3rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Rare ~ Annual
            </MyText>
            <MyText
              as={"h2"}
              style={{
                "--font-size": "2.5rem",
                "--margin-bottom": "3.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              $39.99/year
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Unlock social features
            </MyText>
            {/*             <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Unlock all cards in Binder
            </MyText> */}
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Discord access
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Create up to 60 decks
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Export decks
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "0.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              Create private decks
            </MyText>
            <MyText
              as={"p"}
              style={{
                "--font-size": "2rem",
                "--margin-bottom": "3.5rem",
                "--text-shadow": "none",
                "--text-shadow-hover": "none",
              }}
            >
              +500 Coins
            </MyText>
            <PayPalButtons
              style={{ layout: "vertical" }}
              createSubscription={(data, actions) =>
                createSubscription(data, actions, mapTiers["rareY"])
              }
              onApprove={(data, actions) =>
                onApprove(data, actions, addToast, sessionContext)
              }
              onError={err => {
                setPayPalError(err)
              }}
              /* {...defOpt} */
            />
          </SubBox>

          {/*           <SubBox>
            <h1>Daily</h1>
            <PayPalButtons
              style={{ layout: "vertical" }}
              createSubscription={(data, actions) =>
                createSubscription(data, actions, "P-0V650291CD354102UL5W7A4I")
              }
              onApprove={(data, actions) =>
                onApprove(data, actions, addToast, sessionContext)
              }
              onError={err => {
                setPayPalError(err)
              }}
              // {...defOpt}
            />
          </SubBox> */}
        </Flex_Row>
      )}
      {isPendingPayPal ? "PAYPAL-IS-PENDING" : null}
    </PageLayout>
  )
}

export default Subscribe

const PageLayout = styled.div`
  background: radial-gradient(#555, #111);
  padding: 2rem;
  min-height: 100vh;
  width: 100%;
  color: #cccccc;

  display: flex;
  flex-direction: column;
  /*   justify-content: center; */
  align-items: center;
`

const MyText = styled.p`
  cursor: default;
  font-size: var(--font-size);
  color: #cccccc;
  margin-bottom: var(--margin-bottom);
  text-shadow: var(--text-shadow);

  &:hover {
    text-shadow: var(--text-shadow-hover);
  }
`

const Flex_Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const SubBox = styled.div`
  padding: 2rem 2rem 1rem;
  box-shadow: 0px 0px 15px 3px #181818;
  border-radius: 10px;
  margin: 1rem 2rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &:hover {
    box-shadow: 0px 0px 15px 3px #ff974c;
  }
`

const WrapperGatsbyImg = styled.div`
  cursor: default;
  margin: 4px; //  4px
  width: 30rem;
  /*   height: 41.8rem; */
  perspective: 600px;
  transition: transform 0.25s ease-out;
  transform: scale(1);
  margin-bottom: 3rem;
  /*   margin-bottom: 3rem; */

  /*  &:hover {
    z-index: 10;
    transform: scale(1.3);
  } */

  /*   &:before {
    // pseudoelement non fa confondere Card3D
    content: "${props => props.userAlias}";
    text-align: center;
    position: absolute;
    top: ${props => (props.myProfileCardShape === "moldtk" ? "76%" : "82%")};
    z-index: 1;
    width: 100%;
  } */
`

const GatsbyImgProfile = styled(Img)`
  width: 30rem;
  /*   height: 41.8rem; */
  transition: transform 0.25s ease-out;
  border-radius: 1.5rem;

  &:hover {
    z-index: 10;
    transform: scale(1.05);
  }
`

const ST_svg = styled.div`
  margin: 3rem;
  svg {
    fill: var(--fill);
    stroke: var(--stroke);
    width: var(--width);
    height: var(--height);
  }
`

const Flex_Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`
