import React, { useState, useEffect, useContext } from "react"
import { navigate, Link } from "gatsby"
import styled from "styled-components"
import { useToasts } from "react-toast-notifications"
import SessionContext from "./../context/session"
import { faunaQueries } from "../fauna/query-manager"
import { safeVerifyError, HandleLoginError } from "../fauna/helpers/errors"
import ButtonPlain from "./ButtonPlain"
import InputComponent from "./inputComponent"
import svgarnold from "../assets/svgarnold"
import Footer from "./Footer"

const callRegister = (username, password, alias, sessionContext, addToast) => {
  faunaQueries
    .register(username, password, alias)
    .then(res => {
      if (res === false) {
        addToast("Registration failed", {
          appearance: "error",
        })
      } else {
        addToast("User registered", { appearance: "success" })
        sessionContext.dispatch({ type: "login", data: faunaQueries.getUser() })
        navigate("/mtg-deck-builder/", { replace: true })
      }
    })
    .catch(e => {
      /*       console.log("callRegister -> e", e) */
      if (e.error) {
        addToast(e.error, { appearance: "error" })
      } else {
        addToast("Oops, something went wrong", { appearance: "error" })
      }
    })
}

const callLogin = (username, password, sessionContext, addToast) => {
  faunaQueries
    .login(username, password)
    .then(res => {
      /*       console.log("callLogin -> res", res) */
      if (!res) {
        addToast("Login failed", { appearance: "error" }) // penso che non va qui, va direttamente al catch
      } else {
        addToast("Login successful", { appearance: "success" })
        sessionContext.dispatch({ type: "login", data: faunaQueries.getUser() })
        navigate("/mtg-deck-builder/", { replace: true })
      }
    })
    .catch(e => {
      /*       console.log("callLogin -> e", e) */
      if (e.error) {
        addToast(e.error, { appearance: "error" })
      } else {
        addToast("Oops, something went wrong", { appearance: "error" })
      }
    })
}

const Login = () => {
  const sessionContext = useContext(SessionContext)
  const { user } = sessionContext.state
  /*   console.log("Login -> sessionContext", sessionContext) */
  if (user) {
    navigate("/mtg-deck-builder/", { replace: true })
  }

  // SHOW LOGIN OR REGISTER
  const [showLogin, setShowLogin] = useState(true)
  // STATE FOR LOGIN
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [alias, setAlias] = useState("") // for REGISTER

  const { addToast } = useToasts()

  return showLogin ? (
    <PageLayout>
      <Flex_Col>
        <MyText
          style={{
            "--font-size": "3.2rem",
            "--margin-bottom": "0.5rem",
            "--text-shadow": "none",
            "--text-shadow-hover": "none",
          }}
        >
          MTG Builders
        </MyText>
        <ST_svg
          style={{
            "--stroke": "none",
            "--width": "50px",
            "--height": "50px",
            "--fill": "#ccc",
          }}
        >
          {svgarnold}
        </ST_svg>
      </Flex_Col>
      <form
        onSubmit={e => {
          e.preventDefault()
          callLogin(username, password, sessionContext, addToast)
        }}
        style={{
          width: "100%",
        }}
      >
        <Flex_Col>
          <InputComponent
            width="50%"
            placeholder="Email"
            type="text"
            value={username}
            handleOnChange={setUsername}
          />
          <InputComponent
            width="50%"
            placeholder="Password"
            type="password"
            value={password}
            handleOnChange={setPassword}
          />
          <ButtonPlain
            key={"Log in"}
            hasSimpleLogic={true}
            handleSimpleOnClick={() =>
              callLogin(username, password, sessionContext, addToast)
            }
            buttonText={"Login"}
            opacity={1}
            padding="0rem 2rem"
            minWidth="25%"
            paddingTop="0.2rem"
            lineHeight="1.6"
            letterSpacing="0.25rem"
            fontSize="2rem"
            borderRadius="2rem"
          />
        </Flex_Col>
      </form>
      <ButtonPlain
        key={"Not a member? Sign up!"}
        hasSimpleLogic={true}
        handleSimpleOnClick={() => setShowLogin(false)}
        buttonText={"Not a member? Sign up!"}
        opacity={1}
        padding="0rem 2rem"
        minWidth="25%"
        paddingTop="0.2rem"
        lineHeight="1.6"
        letterSpacing="0.25rem"
        fontSize="2rem"
        borderRadius="2rem"
      />
      <Footer />
    </PageLayout>
  ) : (
    <PageLayout>
      <Flex_Col>
        <MyText
          style={{
            "--font-size": "3.2rem",
            "--margin-bottom": "0.5rem",
            "--text-shadow": "0 1px 12px #ff974c",
            "--text-shadow-hover": "none",
          }}
        >
          MTG Builders
        </MyText>
        <ST_svg
          style={{
            "--stroke": "#ff974c",
            "--width": "50px",
            "--height": "50px",
            "--fill": "#ff974c",
          }}
        >
          {svgarnold}
        </ST_svg>
      </Flex_Col>
      <form
        onSubmit={e => {
          e.preventDefault()
          callRegister(username, password, alias, sessionContext, addToast)
        }}
        style={{
          width: "100%",
        }}
      >
        <Flex_Col>
          <InputComponent
            width="50%"
            placeholder="Email"
            type="text"
            value={username}
            handleOnChange={setUsername}
          />
          <InputComponent
            width="50%"
            placeholder="Password"
            type="password"
            value={password}
            handleOnChange={setPassword}
          />
          <InputComponent
            width="50%"
            placeholder="Username"
            type="text"
            value={alias}
            handleOnChange={setAlias}
          />
          <ButtonPlain
            key={"Log in"}
            hasSimpleLogic={true}
            handleSimpleOnClick={() =>
              callRegister(username, password, alias, sessionContext, addToast)
            }
            buttonText={"Sign up"}
            opacity={1}
            padding="0rem 2rem"
            minWidth="25%"
            paddingTop="0.2rem"
            lineHeight="1.6"
            letterSpacing="0.25rem"
            fontSize="2rem"
            borderRadius="2rem"
          />
        </Flex_Col>
      </form>
      <ButtonPlain
        key={"Already a member? Log in!"}
        hasSimpleLogic={true}
        handleSimpleOnClick={() => setShowLogin(true)}
        buttonText={"Already a member? Log in!"}
        opacity={1}
        padding="0rem 2rem"
        minWidth="25%"
        paddingTop="0.2rem"
        lineHeight="1.6"
        letterSpacing="0.25rem"
        fontSize="2rem"
        borderRadius="2rem"
      />
      <Footer />
    </PageLayout>
  )
}

export default Login

const PageLayout = styled.div`
  background: radial-gradient(#555, #111);
  padding: 2rem;
  min-height: 100vh;
  width: 100%;
  color: #cccccc;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`
const Flex_Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`
const MyText = styled.p`
  font-size: var(--font-size);
  color: #cccccc;
  margin-bottom: var(--margin-bottom);
  text-shadow: var(--text-shadow);
  cursor: default;

  &:hover {
    text-shadow: var(--text-shadow-hover);
  }
`

const ST_svg = styled.div`
  svg {
    fill: var(--fill);
    stroke: var(--stroke);
    width: var(--width);
    height: var(--height);
  }
`

/*     <PageLayout>
      <h1>Sign up</h1>
      <form
        onSubmit={event =>
          callRegister(
            event,
            username,
            password,
            alias,
            sessionContext,
            addToast
          )
        }
      >
        <input
          type="text"
          value={username}
          onChange={event => setUsername(event.target.value)}
          placeholder="Email"
        />
        <input
          type="password"
          value={password}
          onChange={event => setPassword(event.target.value)}
          placeholder="Password"
        />
        <input
          type="text"
          value={alias}
          onChange={event => setAlias(event.target.value)}
          placeholder="Alias"
        />
        <button type="submit">Sign up</button>
      </form>
      <button onClick={() => setShowLogin(true)}>Go to Log In</button>
    </PageLayout> */
