import React, { useState } from "react"
import { navigate, navigateTo } from "gatsby"
import styled from "styled-components"
import { motion } from "framer-motion"

import svgRedMana from "../assets/svgRedMana"
import svgGreenMana from "../assets/svgGreenMana"
import svgBluMana from "../assets/svgBluMana"
import svgBlackMana from "../assets/svgBlackMana"
import svgWhiteMana from "../assets/svgWhiteMana"
import svgupvote from "../assets/svgupvote"

const mapColorToSvg = {
  W: svgWhiteMana,
  U: svgBluMana,
  B: svgBlackMana,
  R: svgRedMana,
  G: svgGreenMana,
}

function handleBoxClick(e, numRefDeck) {
  e.preventDefault()
  e.stopPropagation()
  navigate(`/deck/${numRefDeck}`)
}

function firstSecond(first, second) {
  if (isNaN(first)) {
    return [first, second]
  } else {
    return [second, first]
  }
}

const DeckRow = props => {
  const arrManaColors = props.color.split("")
  const [tempo, piace] = firstSecond(props.first, props.second)
  return (
    <Box
      variants={variants}
      initial="default"
      whileHover="hover"
      whileTap="tap"
      /*       onClick={e => handleBoxClick(e, props.deckRef.value.id)} */
    >
      <Box_Created>
        {tempo.value.slice(5, 10).split("-").join(", ")}
      </Box_Created>
      <Box_Author>{props.alias}</Box_Author>
      <Box_DeckName>{props.name}</Box_DeckName>
      <Box_Format>
        {props.format.slice(0, -3)} {props.format.slice(-3)}
      </Box_Format>
      <Box_Colors>
        {arrManaColors.map(color => mapColorToSvg[color])}
      </Box_Colors>
      <Box_Likes>{piace}</Box_Likes>
      <Box_Upvote>{svgupvote}</Box_Upvote>
    </Box>
  )
}

const Box = styled(motion.div)`
  cursor: pointer;
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-around;
  align-items: center;
  color: #cccccc;
  padding-top: 5px;
  padding-left: 1rem;
  padding-right: 1rem;
  /* height: 4rem; */
  font-size: 1.8rem;
  min-height: 4.5rem;
`

const Text = styled.span`
  margin-top: 0.3rem;
`

const Box_Created = styled.div`
  margin-right: 2.2rem;
`
const Box_Author = styled.div``
const Box_DeckName = styled.div`
  flex-grow: 1;
  text-align: center;
`
const Box_Format = styled.div``
const Box_Colors = styled.div`
  display: flex;
  margin-left: 1rem;
  margin-right: 1.5rem;
`
const Box_Likes = styled.div`
  padding-bottom: 5px;
`
const Box_Upvote = styled.div`
  /*   border: 1px solid green; */
  svg {
    fill: #ff974c;
    opacity: 0.8;
  }
`

const variants = {
  tap: {
    y: 0,
    backgroundColor: "#161616",
    boxShadow:
      "0px 0px 0px 0px #040404, 0px 1px 0px 0px rgba(255,255,255,0.6), 0px 0px 3px 0px rgba(0,0,0,0.3), inset 0px 1px 0px 0px rgba(255,255,255,0.2)",
  },
  hover: {
    y: -3,
    boxShadow:
      "0px 3px 0px 0px #040404, 0px 0px 0px 0px rgba(255,255,255,0.6), 0px 4px 4px 0px rgba(0,0,0,0.3), inset 0px 1px 0px 0px rgba(255,255,255,0.2)",
    backgroundImage:
      "linear-gradient(-180deg,rgba(255, 255, 255, 0.0) 0%,rgba(0, 0, 0, 0.0) 100%)",
  },
  default: {
    y: -4,
    backgroundColor: "#212121",
    boxShadow:
      "0px 4px 0px 0px #040404, 0px 0px 0px 0px rgba(255, 255, 255, 0.6), 0px 6px 4px 0px rgba(0, 0, 0, 0.3), inset 0px 1px 0px 0px rgba(255, 255, 255, 0.2)",
    backgroundImage:
      "linear-gradient(-180deg,rgba(255, 255, 255, 0.1) 0%,rgba(0, 0, 0, 0.1) 100%)",
    transition: {
      delay: 0,
      y: { type: "tween" },
    },
  },
}

export default DeckRow
