import React, { useContext } from "react"
import { navigate } from "gatsby"
/* import { useIdentityContext } from 'react-netlify-identity-widget'; */

import SessionContext from "./../context/session"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  /*   const identity = useIdentityContext();

  if (
    (!identity || !identity.isLoggedIn) &&
    location.pathname !== '/dashboard/login'
  ) {
    navigate('/dashboard/login/', { replace: true });
    return null;
  } */

  const sessionContext = useContext(SessionContext)
  const { user } = sessionContext.state

  if (!user && location.pathname !== "/dashboard/login/") {
    navigate("/dashboard/login/", { replace: true })
    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute
