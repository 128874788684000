import React, { useState, useRef } from "react"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"
import Img from "gatsby-image"

import ButtonMotion from "../components/buttonMotion"
import ButtonPlain from "./ButtonPlain"

const EasyView = ({ cart, setCart, buyCosmetic, currentUserPoints }) => {
  const imgElRef = useRef(null)

  return (
    <DeckDetailsWrapper
      variants={variants}
      initial="closed"
      animate={cart.cosmeticName ? "open" : "closed"}
      transition={{ damping: 300 }}
    >
      <EasyViewToolbar>
        <ButtonMotion handleClick={() => setCart({})} text="Go Back" />
      </EasyViewToolbar>
      <Box
        onMouseMove={ev => {
          Card3D(ev, imgElRef)
        }}
        onMouseLeave={ev => {
          imgElRef.current.style.transform =
            "rotateX(0deg) rotateY(0deg) scale(1)"
          imgElRef.current.style.filter = "brightness(1)"
        }}
        ref={imgElRef}
      >
        <GatsbyImgProfile fluid={cart.fluid} />
      </Box>
      <h3>Cost: {cart.cosmeticCost} coins</h3>
      <ButtonMotion
        handleClick={e => {
          buyCosmetic(cart.cosmeticName)
          setCart({})
        }}
        text="Buy"
      />
      <br />
      <br />
      <p>You have {currentUserPoints} coins</p>
      <ButtonPlain
        key={"Buy coins!"}
        hasSimpleLogic={true}
        handleSimpleOnClick={() => {}}
        buttonText={"Buy coins!"}
        opacity={0.33}
        padding="0rem 2rem"
        minWidth="2rem"
        paddingTop="0.2rem"
        lineHeight="1.6"
        letterSpacing="0.25rem"
        fontSize="2rem"
        borderRadius="2rem"
        disabled={true}
      />
    </DeckDetailsWrapper>
  )
}

const DeckDetailsWrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background: radial-gradient(#555, #111);
  padding: 1rem;
  z-index: 1000000;
  color: #cccccc;

  display: flex;
  flex-direction: column;
  align-items: center;
`

const EasyViewToolbar = styled.nav`
  display: flex;
  justify-content: flex-start;

  align-self: flex-start;
`

const variants = {
  open: { x: 0 },
  closed: {
    x: "-100%",
    transition: {
      delay: 0,
      x: { type: "tween" },
    },
  },
}

export default EasyView

const Box = styled.div`
  cursor: pointer;
  margin: 5rem; //  4px
  width: 27rem;
  height: 37.5rem;
  perspective: 600px;
  transition: transform 0.25s ease-out;
  position: relative;
`

const GatsbyImgProfile = styled(Img)`
  width: 27rem;
  height: 37.5rem;
  transition: transform 0.25s ease-out;
`

function map(val, minA, maxA, minB, maxB) {
  return minB + ((val - minA) * (maxB - minB)) / (maxA - minA)
}

function Card3D(ev, imgElRef) {
  let mouseX = ev.nativeEvent.offsetX
  let mouseY = ev.nativeEvent.offsetY

  let rotateY = map(mouseX, 0, 270, -25, 25) // 180
  let rotateX = map(mouseY, 0, 375, 25, -25) // 250
  let brightness = map(mouseY, 0, 375, 1.5, 0.5) // 250

  imgElRef.current.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale(1.2)`
  imgElRef.current.style.filter = `brightness(${brightness})`
}
