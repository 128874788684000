import React, { useState, useEffect, useContext, useRef } from "react"
import { navigate, Link, useStaticQuery, graphql } from "gatsby"
import { useQueryCache, useQuery, useMutation } from "react-query"
import { useToasts } from "react-toast-notifications"
import styled from "styled-components"
import { motion } from "framer-motion"
import Img from "gatsby-image"

import SessionContext from "./../context/session"
import { faunaQueries } from "../fauna/query-manager"
import { safeVerifyError } from "../fauna/helpers/errors"
import { logoutBecauseUnauthorized } from "../fauna/helpers/util"

import BuyCosmetic from "./BuyCosmetic"
import svgpatreon from "./../assets/svgpatreon"
import svgtwitch from "./../assets/svgtwitch"
import svgfacebook from "./../assets/svgfacebook"
import svgtwitter from "./../assets/svgtwitter"
import svgyoutube from "./../assets/svgyoutube"
import ButtonMotion from "../components/buttonMotion"

const MyProfile = () => {
  // eq: options.name (gatsby-config)
  const data = useStaticQuery(graphql`
    query {
      m15Q: allFile(filter: { sourceInstanceName: { eq: "m15Fonte" } }) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      m15cQ: allFile(filter: { sourceInstanceName: { eq: "m15cFonte" } }) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      m15tokensQ: allFile(
        filter: { sourceInstanceName: { eq: "m15tokensFonte" } }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      m15tokensnyxQ: allFile(
        filter: { sourceInstanceName: { eq: "m15tokensnyxFonte" } }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      moldtokensQ: allFile(
        filter: { sourceInstanceName: { eq: "moldtokensFonte" } }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      artsQ: allFile(filter: { sourceInstanceName: { eq: "artsFonte" } }) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      artsCutQ: allFile(
        filter: { sourceInstanceName: { eq: "artsCutFonte" } }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const sessionContext = useContext(SessionContext)
  const { user } = sessionContext.state
  const { addToast } = useToasts()

  const queryCache = useQueryCache()

  // IN PRODUCTION
  const res_get_my_cosmetics = use_get_my_cosmetics(
    queryCache,
    addToast,
    logoutBecauseUnauthorized,
    sessionContext
  )
  /*   console.log(
    "use_get_my_cosmetics/res_get_my_cosmetics: ",
    res_get_my_cosmetics
  ) */
  // IN DEVELOPMENT
  /* const res_get_my_cosmetics = {
    data: [
      "moldtokensu",
      "moldtokensg",
      "m15tokensm",
      "m15tokensnyxb",
      "king",
      "lucifer",
    ],
  } */

  const [saveMyInfo, saveMyInfoInfo] = useSaveMyInfo(
    queryCache,
    addToast,
    logoutBecauseUnauthorized,
    sessionContext
  )
  /*   console.log("saveMyInfoInfo", saveMyInfoInfo) */

  const [buyCosmetic, buyCosmeticInfo] = useBuyCosmetic(
    queryCache,
    addToast,
    logoutBecauseUnauthorized,
    sessionContext
  )
  /*   console.log("buyCosmetic", buyCosmeticInfo) */

  const [myLinks, setMyLinks] = useState({
    twitch: sessionContext.state?.user?.twitch,
    patreon: sessionContext.state?.user?.patreon,
    youtube: sessionContext.state?.user?.youtube,
    twitter: sessionContext.state?.user?.twitter,
    facebook: sessionContext.state?.user?.facebook,
  })
  const [myProfileCard, setMyProfileCard] = useState({
    back: sessionContext.state?.user?.back,
    sfondo: sessionContext.state?.user?.sfondo,
    shape: sessionContext.state?.user?.shape,
  })

  const [cart, setCart] = useState({})

  function handleLinkChange(fieldString, value) {
    setMyLinks(prev => ({ ...prev, [fieldString]: value }))
  }

  // quando fieldString e' back --> moldtokensu
  // quando fieldString e' sfondo --> orlando
  // (Nota che back ha una lettera finale che indica il colore mentre sfondo non ne ha bisogno.)
  function handleCosmeticClick(
    fieldString,
    cosmeticName,
    shapeOfArtCut,
    fluid
  ) {
    if (res_get_my_cosmetics.data?.includes(cosmeticName)) {
      if (shapeOfArtCut) {
        setMyProfileCard(prev => ({
          ...prev,
          [fieldString]: cosmeticName,
          shape: shapeOfArtCut,
        }))
      } else {
        setMyProfileCard(prev => ({ ...prev, [fieldString]: cosmeticName }))
      }
    } else {
      window.scrollTo(0, 0)
      setCart({
        cosmeticName,
        cosmeticCost: mapNameToCost[cosmeticName],
        fluid,
      })
    }
  }

  const mapNameToCost = {
    lucifer: 1000,
    king: 1000,
    orlando: 1000,
    m15tokensa: 300,
    m15tokensb: 300,
    m15tokensc: 300,
    m15tokensg: 300,
    m15tokensm: 300,
    m15tokensr: 300,
    m15tokensu: 300,
    m15tokensw: 300,
    m15tokensnyxa: 600,
    m15tokensnyxb: 600,
    m15tokensnyxc: 600,
    m15tokensnyxg: 600,
    m15tokensnyxm: 600,
    m15tokensnyxr: 600,
    m15tokensnyxu: 600,
    m15tokensnyxw: 600,
    moldtokensa: 1000,
    moldtokensb: 1000,
    moldtokensc: 1000,
    moldtokensg: 1000,
    moldtokensm: 1000,
    moldtokensr: 1000,
    moldtokensu: 1000,
    moldtokensw: 1000,
  }

  useEffect(() => {
    if (!user && faunaQueries.getUser()) {
      sessionContext.dispatch({ type: "login", data: faunaQueries.getUser() })
    }
    return function cleanup() {
      /*       console.log("Friends.js/unmounting") */
    }
  }, [user, sessionContext])

  const imgElRef = useRef(null)

  const [focusedInput, setFocusedInput] = useState(null)

  return (
    <PageLayout>
      {/* <IntermediaryWrapper> */}
      <BuyCosmetic
        cart={cart}
        setCart={setCart}
        buyCosmetic={buyCosmetic}
        currentUserPoints={sessionContext.state?.user?.points}
      />
      <Left_Profile>
        <Box
          /*             onMouseMove={ev => {
              Card3D(ev, imgElRef)
            }}
            onMouseLeave={ev => {
              imgElRef.current.style.transform =
                "rotateX(0deg) rotateY(0deg) scale(1)"
              imgElRef.current.style.filter = "brightness(1)"
            }}
            ref={imgElRef} */
          myProfileCardShape={myProfileCard["shape"]}
          userAlias={user?.alias}
        >
          <GatsbyImgProfile
            fluid={
              [
                ...data.moldtokensQ.edges,
                ...data.m15tokensnyxQ.edges,
                ...data.m15tokensQ.edges,
              ].find(edge => edge.node.name === myProfileCard["back"]).node
                .childImageSharp.fluid
            }
          />
          <WrapGatsbyImgProfileOverlay>
            <GatsbyImgProfile
              fluid={
                data.artsCutQ.edges.find(
                  edge =>
                    edge.node.name ===
                    myProfileCard["shape"] + myProfileCard["sfondo"]
                ).node.childImageSharp.fluid
              }
            />
          </WrapGatsbyImgProfileOverlay>
        </Box>

        <InputWrapper
          focused={myLinks["twitch"] || focusedInput === "twitch"}
          category="Twitch"
        >
          {svgtwitch}
          <Input
            onFocus={e => setFocusedInput("twitch")}
            onBlur={e => setFocusedInput(null)}
            onChange={e => handleLinkChange("twitch", e.target.value)}
            type="text"
            value={myLinks["twitch"]}
          />
        </InputWrapper>
        <InputWrapper
          focused={myLinks["patreon"] || focusedInput === "patreon"}
          category="Patreon"
        >
          {svgpatreon}
          <Input
            onFocus={e => setFocusedInput("patreon")}
            onBlur={e => setFocusedInput(null)}
            onChange={e => handleLinkChange("patreon", e.target.value)}
            type="text"
            value={myLinks["patreon"]}
          />
        </InputWrapper>
        <InputWrapper
          focused={myLinks["facebook"] || focusedInput === "facebook"}
          category="Facebook"
        >
          {svgfacebook}
          <Input
            onFocus={e => setFocusedInput("facebook")}
            onBlur={e => setFocusedInput(null)}
            onChange={e => handleLinkChange("facebook", e.target.value)}
            type="text"
            value={myLinks["facebook"]}
          />
        </InputWrapper>
        <InputWrapper
          focused={myLinks["twitter"] || focusedInput === "twitter"}
          category="Twitter"
        >
          {svgtwitter}
          <Input
            onFocus={e => setFocusedInput("twitter")}
            onBlur={e => setFocusedInput(null)}
            onChange={e => handleLinkChange("twitter", e.target.value)}
            type="text"
            value={myLinks["twitter"]}
          />
        </InputWrapper>
        <InputWrapper
          focused={myLinks["youtube"] || focusedInput === "youtube"}
          category="Youtube"
        >
          {svgyoutube}
          <Input
            onFocus={e => setFocusedInput("youtube")}
            onBlur={e => setFocusedInput(null)}
            onChange={e => handleLinkChange("youtube", e.target.value)}
            type="text"
            value={myLinks["youtube"]}
          />
        </InputWrapper>
        <ButtonMotion
          handleClick={() => saveMyInfo({ ...myLinks, ...myProfileCard })}
          text="Save changes"
        />
      </Left_Profile>
      <Right_Cosmetics>
        <ST_FlexBacks>
          {data.moldtokensQ.edges.map(edge => (
            <ST_WrapBackImg
              isActive={edge.node.name === myProfileCard["back"]}
              key={edge.node.name}
              variants={variants}
              initial="default"
              whileHover="hover"
              whileTap="tap"
              owned={res_get_my_cosmetics.data?.includes(edge.node.name)}
              onClick={e =>
                handleCosmeticClick(
                  "back",
                  edge.node.name,
                  "moldtk",
                  edge.node.childImageSharp.fluid
                )
              }
            >
              <GatsbyImg
                owned={res_get_my_cosmetics.data?.includes(edge.node.name)}
                fluid={edge.node.childImageSharp.fluid}
              />
            </ST_WrapBackImg>
          ))}
          {/* </ST_FlexBacks>
      <ST_FlexBacks> */}
          {data.m15tokensQ.edges.map(edge => (
            <ST_WrapBackImg
              isActive={edge.node.name === myProfileCard["back"]}
              key={edge.node.name}
              variants={variants}
              initial="default"
              whileHover="hover"
              whileTap="tap"
              owned={res_get_my_cosmetics.data?.includes(edge.node.name)}
              onClick={e =>
                handleCosmeticClick(
                  "back",
                  edge.node.name,
                  "m15tk",
                  edge.node.childImageSharp.fluid
                )
              }
            >
              <GatsbyImg
                owned={res_get_my_cosmetics.data?.includes(edge.node.name)}
                fluid={edge.node.childImageSharp.fluid}
              />
            </ST_WrapBackImg>
          ))}
          {/*       </ST_FlexBacks>
      <ST_FlexBacks> */}
          {data.m15tokensnyxQ.edges.map(edge => (
            <ST_WrapBackImg
              isActive={edge.node.name === myProfileCard["back"]}
              key={edge.node.name}
              variants={variants}
              initial="default"
              whileHover="hover"
              whileTap="tap"
              owned={res_get_my_cosmetics.data?.includes(edge.node.name)}
              onClick={e =>
                handleCosmeticClick(
                  "back",
                  edge.node.name,
                  "m15tk",
                  edge.node.childImageSharp.fluid
                )
              }
            >
              <GatsbyImg
                owned={res_get_my_cosmetics.data?.includes(edge.node.name)}
                fluid={edge.node.childImageSharp.fluid}
              />
            </ST_WrapBackImg>
          ))}
        </ST_FlexBacks>
        <ST_FlexBacks>
          {data.artsQ.edges.map(edge => (
            <ST_WrapBackImg
              isActive={edge.node.name === myProfileCard["sfondo"]}
              key={edge.node.name}
              variants={variants}
              initial="default"
              whileHover="hover"
              whileTap="tap"
              owned={res_get_my_cosmetics.data?.includes(edge.node.name)}
              onClick={e =>
                handleCosmeticClick(
                  "sfondo",
                  edge.node.name,
                  null,
                  edge.node.childImageSharp.fluid
                )
              }
            >
              <GatsbyImg
                owned={res_get_my_cosmetics.data?.includes(edge.node.name)}
                fluid={edge.node.childImageSharp.fluid}
              />
            </ST_WrapBackImg>
          ))}
        </ST_FlexBacks>
      </Right_Cosmetics>
      {/*  </IntermediaryWrapper> */}
    </PageLayout>
  )
}

export default MyProfile

const PageLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: radial-gradient(#555, #111);
  padding-top: 2rem;
  margin: -0.5rem;
`

const IntermediaryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin: -0.5rem;
`

const Left_Profile = styled.div`
  flex-basis: 29rem; // Where we do not provide a flex-basis value at all, the sidebar’s width is equal to the width of its contents
  flex-grow: 1;

  margin: 0.5rem;

  /* align-self: start;
  position: sticky;
  top: 2rem;
  z-index: 100; */

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
`

const Right_Cosmetics = styled.div`
  flex-basis: 0;
  flex-grow: 999;
  /* min-width: 72%; */
  /* min-width: calc(50% - 1rem); */
  min-width: calc(70% - 1rem);

  margin: 0.5rem;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const Box = styled.div`
  cursor: pointer;
  width: 27rem;
  height: 37.5rem;
  perspective: 600px;
  transition: transform 0.25s ease-out;
  position: relative;
  margin-bottom: 3rem;

  filter: drop-shadow(-14px 6px 10px black);

  &:before {
    // pseudoelement non fa confondere Card3D
    content: "${props => props.userAlias}";
    text-align: center;
    position: absolute;
    top: ${props =>
      props.myProfileCardShape === "moldtk" ? "76.8%" : "82.5%"};
    z-index: 1;
    width: 100%;
  }
`

const WrapGatsbyImgProfileOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -100;
`
const GatsbyImgProfile = styled(Img)`
  width: 27rem;
  height: 37.5rem;
  transition: transform 0.25s ease-out;
  border-radius: 1.5rem;
`

const ST_FlexBacks = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const Input = styled.input`
  background: transparent;
  position: relative;
  height: 30px;
  width: 20rem;
  z-index: 200;
  padding: 10px;
  font-size: 20px;
  font-weight: lighter;
  &:focus {
    outline-style: none;
    outline-color: bisque;

    border-width: 3px;
    border-image: linear-gradient(to right, #11998e, #38ef7d);
    border-image-slice: 1;
  }
  border: 0;
  border-bottom: 1px solid #9b9b9b;
  color: #cccccc;
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 30rem; */

  position: relative;
  border-radius: 4px;
  background: transparent;
  z-index: 100;

  margin-bottom: 2.5rem;

  &:after {
    content: "${props => props.category}";
    position: absolute;
    transition: transform 0.15s linear, font-size 0.25s linear;
    top: 50%;
    width: 95%;
    z-index: 100;
    letter-spacing: 1px;
    color: #9b9b9b;
    font-weight: normal;
    left: 20%;
    font-size: ${props => (props.focused ? "14px" : "16px")};
    transform: ${props =>
      props.focused
        ? "translateY(-30px) translateX(-5%);"
        : "translateY(-50%);"};
  }

  > svg {
    margin-right: 1rem;
  }
`

const GatsbyImg = styled(Img)`
  width: 20.2rem;
  height: 28.2rem;
  position: relative;

  // top coin
  &::before {
    content: "";
    position: absolute;
    z-index: 30;
    display: ${props => (props.owned ? "none" : "inline-block")};
    top: 4.3rem;
    right: 3.5rem;
    box-sizing: border-box;
    width: 2em;
    margin: -0.5rem 0 0 0;
    height: 0.66em;
    border-bottom: 0.05em solid white;
    border-right: 0.05em solid white;
    border-radius: 50%;
    transition: all 0.25s ease-out;
    background-image: linear-gradient(
      20deg,
      goldenrod,
      30%,
      khaki,
      34%,
      khaki,
      50%,
      goldenrod,
      54%,
      goldenrod,
      60%,
      wheat,
      64%,
      wheat,
      67%,
      goldenrod,
      71%,
      goldenrod
    );
    box-shadow: 0 0.05em 0 darkgoldenrod, 0 0.1em 0 darkgoldenrod,
      0 0.15em 0 darkgoldenrod, 0 0.2em 0 darkgoldenrod,
      0 0.25em 0 darkgoldenrod, 0 0.3em 0 darkgoldenrod,
      0 0.35em 0.15em rgba(0, 0, 0, 0.5);
  }

  // middle coin
  &::after {
    content: "";
    position: absolute;
    z-index: 20;
    display: ${props => (props.owned ? "none" : "inline-block")};
    top: 5.1rem;
    right: 3.1rem;
    box-sizing: border-box;
    width: 2em;
    margin: -0.5rem 0 0 0;
    height: 0.66em;
    border-bottom: 0.05em solid white;
    border-right: 0.05em solid white;
    border-radius: 50%;
    transition: all 0.25s ease-out;
    background-image: linear-gradient(
      20deg,
      goldenrod,
      30%,
      khaki,
      34%,
      khaki,
      50%,
      goldenrod,
      54%,
      goldenrod,
      60%,
      wheat,
      64%,
      wheat,
      67%,
      goldenrod,
      71%,
      goldenrod
    );
    box-shadow: 0 0.05em 0 darkgoldenrod, 0 0.1em 0 darkgoldenrod,
      0 0.15em 0 darkgoldenrod, 0 0.2em 0 darkgoldenrod,
      0 0.25em 0 darkgoldenrod, 0 0.3em 0 darkgoldenrod,
      0 0.35em 0.15em rgba(0, 0, 0, 0.5);
  }
`
const ST_WrapBackImg = styled(motion.div)`
  cursor: pointer;
  margin: 1rem;
  position: relative;
  border-radius: 1rem;

  filter: ${props =>
    props.isActive ? "drop-shadow(0px 0px 6px #F706CF)" : null};

  transition: filter 0.25s ease-out;

  // V mark
  &::after {
    content: "";
    position: absolute;
    top: 3.5rem;
    right: 3.8rem;
    display: ${props => (props.owned ? "inline-block" : "none")};
    transform: rotate(45deg);
    height: 2.4rem;
    width: 1.2rem;
    border-bottom: 0.7rem solid #78b13f;
    border-right: 0.7rem solid #78b13f;
  }

  // bottom coin
  &::before {
    content: "";
    position: absolute;
    z-index: 10;
    display: ${props => (props.owned ? "none" : "inline-block")};
    top: 5.9rem;
    right: 3.4rem;
    box-sizing: border-box;
    width: 2em;
    margin: -0.5rem 0 0 0;
    height: 0.66em;
    border-bottom: 0.05em solid white;
    border-right: 0.05em solid white;
    border-radius: 50%;
    transition: all 0.25s ease-out;
    background-image: linear-gradient(
      20deg,
      goldenrod,
      30%,
      khaki,
      34%,
      khaki,
      50%,
      goldenrod,
      54%,
      goldenrod,
      60%,
      wheat,
      64%,
      wheat,
      67%,
      goldenrod,
      71%,
      goldenrod
    );
    box-shadow: 0 0.05em 0 darkgoldenrod, 0 0.1em 0 darkgoldenrod,
      0 0.15em 0 darkgoldenrod, 0 0.2em 0 darkgoldenrod,
      0 0.25em 0 darkgoldenrod, 0 0.3em 0 darkgoldenrod,
      0 0.35em 0.15em rgba(0, 0, 0, 0.5);
  }
`

const variants = {
  tap: {
    boxShadow: "0px 0px 8px 8px #2DE2E6",
  },
  hover: {
    boxShadow: "0px 0px  6px 4px #FF974C",
  },
  default: {
    boxShadow: "0px 0px 0px 0px #000000",
    transition: {
      delay: 0,
    },
  },
}

function useSaveMyInfo(
  queryCache,
  addToast,
  logoutBecauseUnauthorized,
  sessionContext
) {
  return useMutation(
    values =>
      fetchSaveMyInfo(
        queryCache,
        values,
        addToast,
        logoutBecauseUnauthorized,
        sessionContext
      ),
    {
      onMutate: async values => {
        const oldUser = faunaQueries.getUser()
        /*         const { discordCode, tier, start_time, plan_id, isYearly } = oldUser */
        sessionContext.dispatch({
          type: "login",
          data: { ...oldUser, ...values },
        })
        return () =>
          sessionContext.dispatch({
            type: "login",
            data: oldUser,
          })
      },
      onError: (error, values, rollback) => {
        /*         console.log("useSaveMyInfo/onError: ", error) */
        if (
          error.error ||
          error.description === "Unauthorized" ||
          error.message === "permission denied"
        ) {
          logoutBecauseUnauthorized(sessionContext, addToast, error.error)
        } else {
          const errorDescription = safeVerifyError(error, [
            "requestResult",
            "responseContent",
            "errors",
            0,
            "cause",
            0,
            "description",
          ])
          addToast(errorDescription, { appearance: "error" })
        }
        if (rollback) {
          rollback()
        }
      },
      onSuccess: (data, values) => {
        /*         console.log("useSaveMyInfo/onSuccess: ", data) */
        addToast("Changes saved", { appearance: "success" })
      },
      onSettled: (data, error, values, rollback) => {
        if (sessionContext.state?.user?.alias) {
          queryCache.invalidateQueries([
            "QM_get_user_info",
            sessionContext.state.user.alias,
          ])
        }
      },
    }
  )
}

async function fetchSaveMyInfo(
  queryCache,
  values,
  addToast,
  logoutBecauseUnauthorized,
  sessionContext
) {
  try {
    const response = await faunaQueries.QM_save_my_info(values)
    /*     console.log("fetchSaveMyInfo/response: ", response) */
    return response
  } catch (err) {
    /*     console.log("fetchSaveMyInfo/err: ", err) */
    throw err
  }
}

function map(val, minA, maxA, minB, maxB) {
  return minB + ((val - minA) * (maxB - minB)) / (maxA - minA)
}

function Card3D(ev, imgElRef) {
  let mouseX = ev.nativeEvent.offsetX
  let mouseY = ev.nativeEvent.offsetY

  let rotateY = map(mouseX, 0, 270, -25, 25) // 180
  let rotateX = map(mouseY, 0, 375, 25, -25) // 250
  let brightness = map(mouseY, 0, 375, 1.5, 0.5) // 250

  imgElRef.current.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale(1.2)`
  imgElRef.current.style.filter = `brightness(${brightness})`
}

function use_get_my_cosmetics(
  queryCache,
  addToast,
  logoutBecauseUnauthorized,
  sessionContext
) {
  return useQuery(
    [`QM_get_my_cosmetics`],
    (prefix, key) =>
      fetch_get_my_cosmetics(
        queryCache,
        addToast,
        logoutBecauseUnauthorized,
        sessionContext
      ),
    {
      staleTime: 0,
      /* keepPreviousData: true, */

      onError: (error, values, rollback) => {
        /*         console.log("use_get_my_cosmetics/onError: ", error) */
        if (
          error.error ||
          error.description === "Unauthorized" ||
          error.message === "permission denied" ||
          error.message === "Failed to fetch" // DAFARE Aggiunto dopo. Prima di implementare Fauna.
        ) {
          /*           console.log("uno") */
          logoutBecauseUnauthorized(
            sessionContext,
            addToast,
            error.error || error.message
          )
        } else {
          /*           console.log("due") */
          const errorDescription = safeVerifyError(error, [
            "requestResult",
            "responseContent",
            "errors",
            0,
            "cause",
            0,
            "description",
          ])
          addToast(errorDescription, { appearance: "error" })
        }
      },
      onSuccess: (data, values) => {
        /*         console.log("use_get_my_cosmetics/onSuccess: ", data) */
      },
    }
  )
}

async function fetch_get_my_cosmetics(
  queryCache,
  addToast,
  logoutBecauseUnauthorized,
  sessionContext
) {
  try {
    const response = await faunaQueries.QM_get_my_cosmetics()
    /*     console.log("fetch_get_my_cosmetics -> response", response) */
    return response
  } catch (err) {
    /*     console.log("fetch_get_my_cosmetics -> err", err) */
    throw err
  }
}

// nel onSuccess di useBuyCosmetic, fare due cose:
// 1) aggiungere il cosmetic acquistato alla cache
// 2) invalidare la query use_get_my_cosmetics
function useBuyCosmetic(
  queryCache,
  addToast,
  logoutBecauseUnauthorized,
  sessionContext
) {
  return useMutation(
    values =>
      fetchBuyCosmetic(
        queryCache,
        values,
        addToast,
        logoutBecauseUnauthorized,
        sessionContext
      ),
    {
      /*       onMutate: async values => {
        const cachedCosmetics = queryCache.getQueryData(["QM_get_my_cosmetics"]) // { data: ['king', 'orlando']}
        console.log("🚀 🚀  cachedCosmetics", cachedCosmetics)
        queryCache.setQueryData(["QM_get_my_cosmetics"], oldData =>
          console.log("oldData", oldData)
        )
        return () =>
          queryCache.setQueryData(["QM_get_my_cosmetics"], cachedCosmetics)
      }, */
      onMutate: async values => {
        const cachedCosmetics = queryCache.getQueryData(["QM_get_my_cosmetics"])
        queryCache.setQueryData(["QM_get_my_cosmetics"], oldData => [
          ...oldData,
          values,
        ])
        return () =>
          queryCache.setQueryData(["QM_get_my_cosmetics"], cachedCosmetics)
      },
      onError: (error, values, rollback) => {
        /*         console.log("useBuyCosmetic/onError: ", error) */
        if (
          error.error ||
          error.description === "Unauthorized" ||
          error.message === "permission denied" ||
          error.message === "Failed to fetch" // DAFARE Aggiunto dopo. Prima di implementare Fauna.
        ) {
          logoutBecauseUnauthorized(sessionContext, addToast, error.error)
        } else {
          const errorDescription = safeVerifyError(error, [
            "requestResult",
            "responseContent",
            "errors",
            0,
            "cause",
            0,
            "description",
          ])
          addToast(errorDescription, { appearance: "error" })
        }
        if (rollback) {
          rollback()
        }
      },
      onSuccess: (data, values) => {
        addToast("Preciousss!", { appearance: "success" })
        const oldUser = faunaQueries.getUser()
        sessionContext.dispatch({
          type: "login",
          data: { ...oldUser, points: oldUser.points - data },
        })
        // or try to requery my_info (...ma non my_info qui! infatti prendo le mie info direttamente dal context, ed e' per questo che lo dispatcho)
      },
      onSettled: (data, error, values, rollback) => {
        queryCache.invalidateQueries(["use_get_my_cosmetics"])
      },
    }
  )
}

async function fetchBuyCosmetic(
  queryCache,
  values,
  addToast,
  logoutBecauseUnauthorized,
  sessionContext
) {
  try {
    const response = await faunaQueries.QM_buy_cosmetic(values)
    /*     console.log("fetchBuyCosmetic/response: ", response) */
    return response
  } catch (err) {
    /*     console.log("fetchBuyCosmetic/err: ", err) */
    throw err
  }
}
