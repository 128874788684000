import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import svgtwitch from "./../assets/svgtwitch"
import svgtwitter from "./../assets/svgtwitter"
import svgyoutube from "./../assets/svgyoutube"
import svginstagram from "./../assets/svginstagram"

// instagram

const Footer = props => {
  return (
    <WrapperFooter>
      <WrapperLogos>
        <ExtLink
          as="a"
          href="https://www.twitter.com/mtgbuilders"
          target="_blank"
          rel="noopener noreferrer"
        >
          {svgtwitter}
        </ExtLink>
        <ExtLink
          as="a"
          href="https://www.youtube.com/channel/UCji8a5xLWS_2NoEvUqT_eGA"
          target="_blank"
          rel="noopener noreferrer"
        >
          {svgyoutube}
        </ExtLink>
        <ExtLink
          as="a"
          href="https://www.twitch.tv/mtgbuilders"
          target="_blank"
          rel="noopener noreferrer"
        >
          {svgtwitch}
        </ExtLink>
        <ExtLink
          as="a"
          href="https://www.instagram.com/mtg.builders"
          target="_blank"
          rel="noopener noreferrer"
        >
          {svginstagram}
        </ExtLink>
      </WrapperLogos>
      <MyText
        as={"p"}
        style={{
          "--font-size": "1.6rem",
          "--margin-bottom": "1.5rem",
          "--text-shadow": "none",
          "--text-shadow-hover": "none",
        }}
      >
        help.mtg.builders@gmail.com
      </MyText>
      <Flex_Row>
        <Link
          to="/privacy/"
          /*           style={{
            color: `white`,
            textDecoration: `none`,
          }} */
        >
          privacy
        </Link>
        &nbsp;~&nbsp;
        <Link
          to="/faq/"
          /*           style={{
            color: `white`,
            textDecoration: `none`,
          }} */
        >
          faq
        </Link>
        &nbsp;~&nbsp;
        <Link
          to="/roadmap/"
          /*           style={{
            color: `white`,
            textDecoration: `none`,
          }} */
        >
          roadmap
        </Link>
      </Flex_Row>
      <MyText
        as={"p"}
        style={{
          "--font-size": "1.6rem",
          "--margin-bottom": "0rem",
          "--text-shadow": "none",
          "--text-shadow-hover": "none",
        }}
      >
        © 2021 MTG Builders All Rights Reserved. Wizards of the Coast, Magic:
        The Gathering, and their logos are trademarks of Wizards of the Coast
        LLC in the United States and other countries. This web site is not
        affiliated with, endorsed, sponsored, or specifically approved by
        Wizards of the Coast LLC. This web site may use the trademarks and other
        intellectual property of Wizards of the Coast LLC, which is permitted
        under Wizards' Fan Site Policy. Responsibility for comments, posts,
        messages and any other user-generated content lies with their respective
        authors. We do not monitor or necessarily agree with any personal
        opinions or other expressions published in any such content.
      </MyText>
    </WrapperFooter>
  )
}

export default Footer

const WrapperFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 6rem 4rem 4rem 4rem;
`

const Flex_Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2.5rem;
`

const WrapperLogos = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 1rem 0 1rem;
`

const ExtLink = styled.a`
  margin: 5px;

  &:hover {
    svg {
      filter: drop-shadow(0px 4px 6px #ff974c);
      opacity: 1;
    }
  }

  svg {
    filter: drop-shadow(0px 4px 6px black);
    opacity: 0.7;
  }
`

const MyText = styled.p`
  cursor: default;
  font-size: var(--font-size);
  color: #cccccc;
  margin-bottom: var(--margin-bottom);
  text-shadow: var(--text-shadow);
  text-align: center;

  &:hover {
    text-shadow: var(--text-shadow-hover);
  }
`
